<template>
  <div>
    <div class="header">
      <div>
        <el-page-header @back="goBack" :content="recruitTitle + '返佣名单'">
        </el-page-header>
      </div>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="download()"
        >导出名单</el-button
      >
    </div>
    <ListFilter :total="total" @close="putAway">
      关键字：
      <el-input
        placeholder="请输入关键字"
        v-model="formInline.keywords"
        @input="loadData()"
        size="mini"
        style="margin-right: 10px; width: 300px"
        prefix-icon="el-icon-search"
        clearable
      >
      </el-input>
      状态：
      <el-select
        placeholder="确认结果"
        size="mini"
        v-model="formInline.state"
        @change="loadData()"
        clearable=""
      >
        <el-option
          v-for="item in cofirmOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </ListFilter>
    <el-table
      :data="list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="姓名" width="120">
        <template slot-scope="scope">
          <span class="clickColor">{{
            scope.row.userName || scope.row.userCode
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="结算金额（元）" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>

      <el-table-column label="结算状态">
        <template slot-scope="scope">
          {{ scope.row.state }}
        </template>
      </el-table-column>

      <el-table-column
        label="当前结算次数"
        prop="currentTimes"
      ></el-table-column>

      <el-table-column label="总结算次数" prop="totalTimes"> </el-table-column>

      <el-table-column label="结算时间" prop="payTime"> </el-table-column>

      <el-table-column label="备注" prop="remark"> </el-table-column>

      <!-- <el-table-column label="操作" fixed="right" width="180">
        <template>
          <el-button slot="reference" type="text">人员确认</el-button>
          <el-button type="text">查看操作历史</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const RecruitOrderList = api('/recruitOrder')('recruitOrder.list.json');
import { parseJsonToTable } from '/src/lib/dev';
export default {
  components: {
    ListFilter: () => import('/src/components/filter')
  },
  data() {
    return {
      code: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
        state: ''
      },
      list: [],
      cofirmOptions: [
        {
          label: '待确认',
          value: '待确认'
        },
        {
          label: '通过',
          value: '通过'
        },
        {
          label: '未通过',
          value: '未通过'
        },
        {
          label: '未入职',
          value: '未入职'
        },
        {
          label: '已入职',
          value: '已入职'
        },
        {
          label: '已离职',
          value: '已离职'
        }
      ],
      selectCode: '',
      selectData: [],
      recruitTitle: ''
    };
  },
  created() {
    this.code = this.$route.query.code;
    this.loadData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData() {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        linkRecruit: this.code
      };
      RecruitOrderList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },

    putAway() {
      this.pageNumber = 1;
      this.formInline.keywords = '';
      this.loadData();
    },

    goBack() {
      window.history.go(-1);
    },
    handleSelectionChange(val) {
      this.selectData = val;
    },
    download() {
      if (this.selectData.length === 0) {
        return this.$message.error('请先选择数据！');
      }
      const _list = this.selectData.map(it => {
        return {
          ...it
        };
      });
      parseJsonToTable(
        '返佣名单',
        {
          编号: 'code',
          用户编号: 'userCode',
          用户姓名: 'userName',
          结算金额: 'amount',
          结算状态: 'state',
          当前结算次数: 'currentTimes',
          总结算次数: 'totalTimes',
          结算时间: 'payTime',
          备注: 'remark'
        },
        _list,
        true
      );
    }
  }
};
</script>

<style></style>
